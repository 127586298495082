<template>
  <Layout>
    <div class="base-width">
      <!-- 面包屑 -->
      <BreadCrumb :list='routes' />
      <div class="c-card s16 color-4 md-feedback">
        <form class="form">
          <p>尊敬的用户：</p> 
          <div class="form-item">
            <p class="tips">您好！为了给您提供更好的产品和服务，我们希望收集您使用易筑的看法或建议。对您的配合和支持表示衷心感谢！</p> 
            <textarea placeholder="请输入留言" class="area border"></textarea>
          </div>
          <div class="form-item">
            <p class="tips">为了便于我们核查问题，可上传遇到问题的截图。</p>
            <BaseFile :max='4'></BaseFile>
          </div>
          <div class="form-item">
            <p class="tips">为了方便我们与您联系，可填写您的手机号。</p>
            <input type="text" placeholder="请输入您的手机号" class="ipt border">
          </div>
          <div class="text-center">
            <BaseButton tag='button' size='large' class="btn-submit">提交</BaseButton>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          name: '首页',
          link: '/'
        }, {
          name: '反馈建议',
          link: ''
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.c-breadcrumbs{
  margin: 0.27rem 0 .16rem;
}
.md-feedback{
  width: 12.8rem;
  padding: .33rem .3rem .39rem;
  margin: 0 auto .4rem;
  line-height: calc(30 / 16 * 1em);
  .form{}
  .form-item{
    margin-bottom: .22rem;
  }
  .tips{
    margin-bottom: .13rem;
  }
  .border{
    border: solid 1px #cae1ec;
    border-radius: .04rem;
  }
  .place{
    color: #999;
  }
  .ipt,.area{
    width: 100%;
    display: block;
    &::placeholder{
      color: #999;
    }
  }
  .ipt{
    height: .5rem; line-height: .5rem;
    padding: 0 .17rem;
  }
  .area{
    height: 1.5rem;
    padding: .17rem;
  }
  .btn-submit{
    min-width: 1.5rem;
    margin-top: .29rem;
  }
}
</style>